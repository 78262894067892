export const version = "1.0";

export const apiURL = process.env.REACT_APP_API_URL + "/api/v1";
export const baseURL = process.env.REACT_APP_API_URL;
export const authKey = "Basic " + btoa(process.env.REACT_APP_CLIENT_ID + ":" + process.env.REACT_APP_CLIENT_SECRET);

export const fromMonthToValue = (month) => {
  switch (month) {
    case "January":
      return "01";
    case "February":
      return "02";
    case "March":
      return "03";
    case "April":
      return "04";
    case "May":
      return "05";
    case "June":
      return "06";
    case "July":
      return "07";
    case "August":
      return "08";
    case "September":
      return "09";
    case "October":
      return "10";
    case "November":
      return "11";
    case "December":
      return "12";
    default:
      return "01";
  }
};

export const fromValueToMonth = (value) => {
  switch (value) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    case "12":
      return "December";
    default:
      return "Jan";
  }
};

export const monthsArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const yearsArr = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026"];

export const colors = ["#2FA1FE", "#45CBCB", "#56CB73", "#FBD432", "#F3647B", "#9761E5", "#606BE6"];
